import type { RouteRecordRaw } from 'vue-router'

import { Permission } from '@/constants/permission.enum'
import { permissionMiddleware } from '@/middleware/permission.middleware'
import ROUTE_NAME from '@/router/routeName'

export const debtorsRoutes: RouteRecordRaw[] = [
	{
		beforeEnter: permissionMiddleware([Permission.DEBTOR_READ]),
		component: () => import('@/modules/debtors/views/DebtorsView.vue'),
		name: ROUTE_NAME.DEBTORS.INDEX,
		path: '/debtors',
		redirect: { name: ROUTE_NAME.DEBTORS.CUSTOMERS.INDEX },
		children: [
			{
				component: () => import('@/modules/debtors/views/employees/DebtorsEmployeeCasesView.vue'),
				name: ROUTE_NAME.DEBTORS.EMPLOYEES.INDEX,
				path: 'employees',
			},
			{
				component: () => import('@/modules/debtors/views/customers/DebtorsCustomerCasesView.vue'),
				name: ROUTE_NAME.DEBTORS.CUSTOMERS.INDEX,
				path: 'customers',
				redirect: { name: ROUTE_NAME.DEBTORS.CUSTOMERS.NEW },
				children: [
					{
						component: () => import('@/modules/debtors/views/customers/DebtorsCustomerNewCasesView.vue'),
						name: ROUTE_NAME.DEBTORS.CUSTOMERS.NEW,
						path: 'new',
					},
					{
						component: () => import('@/modules/debtors/views/customers/DebtorsCustomerOpenCasesView.vue'),
						name: ROUTE_NAME.DEBTORS.CUSTOMERS.OPEN,
						path: 'open',
					},
					{
						component: () => import('@/modules/debtors/views/customers/DebtorsCustomerClosedCasesView.vue'),
						name: ROUTE_NAME.DEBTORS.CUSTOMERS.CLOSED,
						path: 'closed',
					},
				],
			},
		],
	},
	{
		beforeEnter: permissionMiddleware([Permission.DEBTOR_READ]),
		component: () => import('@/modules/debtors/views/customers/DebtorsCustomerCasesDetailView.vue'),
		name: ROUTE_NAME.DEBTORS.CUSTOMERS.CASE_DETAIL,
		path: '/debtors/customers/case/:caseUuid',
	},
	{
		beforeEnter: permissionMiddleware([Permission.DEBTOR_READ]),
		component: () => import('@/modules/debtors/views/customers/DebtorsCustomerCasesDetailView.vue'),
		name: ROUTE_NAME.DEBTORS.CUSTOMERS.CUSTOMER_DETAIL,
		path: '/debtors/customers/precase/:customerUuid',
	},
	{
		beforeEnter: permissionMiddleware([Permission.DEBTOR_READ]),
		component: () => import('@/modules/debtors/views/employees/DebtorsEmployeeCasesDetailView.vue'),
		name: ROUTE_NAME.DEBTORS.EMPLOYEES.DETAIL,
		path: '/debtors/employees/:employeeUuid',
	},
]
