/* eslint-disable camelcase */
import type { Axios, AxiosInstance, InternalAxiosRequestConfig } from 'axios'
import pkceChallenge from 'pkce-challenge'

import { oAuthClient } from '@/http/oAuthClient.ts'
import { logError } from '@/utils/logger.util'

interface OAuth2VueClientOptions {
	axios: Axios | AxiosInstance
	tokenEndpoint: string
	authorization: {
		clientId: string
		url: string
		logoutUrl: string
		redirectUri: string
		postLogoutRedirectUri: string
		grantType: GrantType
		scopes: string[]
	}
	userInfoEndpoint?: string
	/*
	 * If offline is true, the client will work without a real login
	 */
	offline?: boolean
}

export interface OAuth2ClientTokens {
	access_token: string
	expires_in: number
	refresh_token: string
	id_token: string
	scope: string
	token_type: string
}

interface ClientOptions {
	client_id: string
	code?: string
	code_verifier?: string
	state?: string
	redirect_uri?: string
	username?: string
	password?: string
	id_token?: string
	grant_type: GrantType
	scopes?: string
}

export interface OAuth2ClientTokensWithExpiration extends OAuth2ClientTokens {
	expires_at: number
}

interface OAuth2ClientOptions {
	axios: Axios | AxiosInstance
	clientId: string
	tokenEndpoint: string
	scopes?: string[]
}

export interface OAuth2ClientTokens {
	access_token: string
	expires_in: number
	refresh_token: string
	id_token: string
	scope: string
	token_type: string
}

export interface OAuth2ClientTokensWithExpiration extends OAuth2ClientTokens {
	expires_at: number
}

class TokenStore {
	private _promise: Promise<void> | null = null

	constructor(
		private readonly options: OAuth2ClientOptions,
		tokens?: OAuth2ClientTokensWithExpiration
	) {
		this.setTokens(tokens)
	}

	public setTokens(tokens?: OAuth2ClientTokensWithExpiration): void {
		if (!tokens) {
			return
		}

		localStorage.setItem('tokens', JSON.stringify(tokens))
	}

	public async getAccessToken(): Promise<string> {
		if (this.accessTokenExpired()) {
			await this.refreshToken()
		}

		return this.getTokens().access_token
	}

	public clearTokens(): void {
		localStorage.removeItem('tokens')
	}

	public getTokens(): OAuth2ClientTokensWithExpiration {
		return JSON.parse(localStorage.getItem('tokens') as string) as OAuth2ClientTokensWithExpiration
	}

	public getRefreshToken(): string {
		return this.getTokens().refresh_token
	}

	private async refreshToken(): Promise<void> {
		if (this._promise != null) {
			return this._promise
		}

		this._promise = new Promise((resolve, reject) => {
			this.getNewAccessToken(this.getRefreshToken())
				.then((tokens) => {
					this.setTokens(tokens)
					resolve()
				})
				.catch(() => {
					logError('Failed to refresh access token, trying again...')

					setTimeout(() => {
						this.getNewAccessToken(this.getRefreshToken())
							.then((tokens) => {
								this.setTokens(tokens)
								resolve()
							})
							.catch(() => {
								reject(new Error('Failed to refresh access token'))
							})
					}, 1000)
				})
				.finally(() => {
					this._promise = null
				})
		})

		return this._promise
	}

	private async getNewAccessToken(refreshToken: string): Promise<OAuth2ClientTokensWithExpiration> {
		const response = await this.options.axios.post<OAuth2ClientTokens>(
			this.options.tokenEndpoint,
			{
				grant_type: 'refresh_token',
				refresh_token: refreshToken,
				client_id: this.options.clientId,
				scope: this.options.scopes?.join(' '),
			},
			{
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
			}
		)

		return {
			refresh_token: response.data.refresh_token,
			token_type: response.data.token_type,
			expires_in: response.data.expires_in,
			id_token: response.data.id_token,
			scope: response.data.scope,
			access_token: response.data.access_token,
			expires_at: Date.now() + response.data.expires_in * 1000,
		}
	}

	private accessTokenExpired(): boolean {
		return Date.now() >= this.getTokens().expires_at
	}
}

export type GrantType = 'ad' | 'authorization_code' | 'password' | 'refresh_token'

export class OAuth2ZitadelClient {
	private client: TokenStore | null = null
	private readonly offline: boolean

	constructor(private readonly options: OAuth2VueClientOptions) {
		this.offline = options.offline ?? false
		this.client = this.createClient()
	}

	private createClient(tokens?: OAuth2ClientTokensWithExpiration): TokenStore {
		return new TokenStore(
			{
				axios: this.options.axios,
				clientId: this.options.authorization.clientId,
				tokenEndpoint: this.options.tokenEndpoint,
			},
			tokens
		)
	}

	public getClient(): TokenStore | null {
		return this.client
	}

	private removeClient(): void {
		this.client?.clearTokens()
		this.client = null
	}

	public isOffline(): boolean {
		return this.offline
	}

	public loginOffline(): void {
		this.client?.setTokens({
			access_token: '',
			expires_at: 0,
			expires_in: 0,
			id_token: '',
			refresh_token: '',
			scope: '',
			token_type: '',
		})
	}

	async getUserInfo(): Promise<unknown> {
		if (this.client === null) {
			throw new Error('Client is not logged in')
		}

		if (this.options.userInfoEndpoint === undefined) {
			throw new Error('User info endpoint is not defined')
		}

		const response = await this.options.axios.get(this.options.userInfoEndpoint, {
			headers: {
				Authorization: `Bearer ${this.client.getTokens().access_token}`,
			},
		})

		return response.data
	}

	public async getLoginUrl(): Promise<string> {
		const searchParams = new URLSearchParams()

		const codes = await pkceChallenge()

		localStorage.setItem('code_verifier', codes.code_verifier)

		searchParams.append('client_id', this.options.authorization.clientId)
		searchParams.append('redirect_uri', this.options.authorization.redirectUri)
		searchParams.append('response_type', 'code')
		searchParams.append('prompt', 'login')
		searchParams.append('scope', this.options.authorization.scopes?.join(' ') ?? '')
		searchParams.append('code_challenge', codes.code_challenge)
		searchParams.append('code_challenge_method', 'S256')

		return `${this.options.authorization.url}?${searchParams.toString()}`
	}

	public async getLogoutUrl(): Promise<string> {
		const searchParams = new URLSearchParams()
		searchParams.append('post_logout_redirect_uri', this.options.authorization.postLogoutRedirectUri)

		return `${this.options.authorization.logoutUrl}?${searchParams.toString()}`
	}

	public async loginPassword(username: string, password: string): Promise<void> {
		if (this.options.offline) {
			this.loginOffline()
			return
		}

		const tokenStore = await this.login({
			grant_type: 'password',
			username,
			password,
			client_id: this.options.authorization.clientId,
			scopes: this.options.authorization.scopes?.join(' '),
		})

		this.client = this.createClient(tokenStore.getTokens())
	}

	private async login(clientOptions: ClientOptions): Promise<TokenStore> {
		const { data } = await this.options.axios.post<OAuth2ClientTokens>(this.options.tokenEndpoint, clientOptions, {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		})

		return new TokenStore(
			{
				axios: this.options.axios,
				clientId: clientOptions.client_id,
				tokenEndpoint: this.options.tokenEndpoint,
				scopes: this.options.authorization.scopes,
			},
			{
				...data,
				expires_at: Date.now() + data.expires_in * 1000,
			}
		)
	}

	public async loginAuthorization(code: string): Promise<void> {
		if (this.options.offline) {
			this.loginOffline()
			return
		}

		const codeVerifier = localStorage.getItem('code_verifier')

		const tokenStore = await this.login({
			grant_type: this.options.authorization.grantType,
			code: code,
			redirect_uri: this.options.authorization.redirectUri,
			code_verifier: codeVerifier ?? undefined,
			client_id: this.options.authorization.clientId,
			scopes: this.options.authorization.scopes?.join(' ') ?? '',
		})

		const tokens = tokenStore.getTokens()

		localStorage.setItem('id_token', tokens.id_token)

		this.client = this.createClient(tokens)

		localStorage.removeItem('code_verifier')
	}

	public async logout(): Promise<void> {
		this.removeClient()
	}

	public isLoggedIn(): boolean {
		if (this.options.offline) {
			return true
		}

		const client = this.getClient()
		return client?.getTokens() != null
	}

	async addAuthorizationHeader(
		config: InternalAxiosRequestConfig<unknown>
	): Promise<InternalAxiosRequestConfig<unknown>> {
		const client = this.getClient()

		if (client === null) {
			return config
		}

		if (this.isOffline()) {
			return config
		}

		try {
			const token = await client.getAccessToken()

			config.headers.Authorization = `Bearer ${token}`
		} catch (error) {
			logError('Failed to get access token, logging out')
			await oAuthClient.logout()
			throw new Error('Failed to get access token')
		}

		return config
	}
}
