const ROUTE_NAME = {
	ACCIDENTS: {
		ALL: 'route_name.accidents.all',
		CONCEPT: 'route_name.accidents.concept',
		DETAILS: {
			ACCIDENT: 'route_name.accidents.details.accident',
			ACTIVITY: 'route_name.accidents.details.activity',
			DOCUMENTS: 'route_name.accidents.details.documents',
			GENERAL: 'route_name.accidents.details.general',
			INDEX: 'route_name.accidents.details.index',
		},
		DONE: 'route_name.accidents.done',
		INDEX: 'route_name.accidents.index',
		OPEN: 'route_name.accidents.open',
		PROGRESS: 'route_name.accidents.progress',
		REPORT: 'route_name.accidents.report',
	},
	REFERRAL_PREMIUM: {
		ACHIEVED: 'route_name.application_premium.achieved',
		ACTIVE: 'route_name.application_premium.active',
		ALL: 'route_name.application_premium.all',
		CONCEPT: 'route_name.application_premium.concept',
		DENIED: 'route_name.application_premium.denied',
		DETAIL: 'route_name.application_premium.detail',
		INDEX: 'route_name.application_premium.index',
		PAID: 'route_name.application_premium.paid',
		TO_BE_PAID: 'route_name.application_premium.to_be_paid',
	},
	AUTH: {
		AD_CALLBACK: 'route_name.authentication.ad_callback',
		LOGOUT: 'route_name.authentication.logout',
		LOGIN: 'route_name.authentication.login',
		MAINTENANCE: 'route_name.authentication.maintenance',
		NO_PERMISSION: 'route_name.authentication.no_permission',
	},
	BASE: 'route_name.base',
	CLAIMS: {
		CONCEPT: {
			INDEX: 'route_name.claims.concept.index',
		},
		CREATE: 'route_name.claims.create',
		DETAIL: {
			INDEX: 'route_name.claims.detail.index',
		},
		INDEX: 'route_name.claims.index',
	},
	COMPENSATIONS: {
		ADVANCES: {
			ADMIN: 'route_name.compensations.admin.advances',
			DETAIL: 'route_name.compensations.advances.detail',
			INDEX: 'route_name.compensations.advances.index',
			STAFF: 'route_name.compensations.staff.advances',
		},
		ADVANCES_CORRECT: 'route_name.compensations.advances_correct',
		ADVANCES_REQUIRED_ATTENTION: 'route_name.compensations.advances_required_attention',
		INDEX: 'route_name.compensations.index',
		PAYMENTS: {
			ADMIN: 'route_name.compensations.admin.payments',
			DETAIL: 'route_name.compensations.payments.detail',
			INDEX: 'route_name.compensations.payments.index',
			STAFF: 'route_name.compensations.staff.payments',
		},
		CORRECTIONS: {
			INDEX: 'route_name.compensations.corrections.index',
			IN_PROGRESS: {
				INDEX: 'route_name.compensations.corrections.in_progress.index',
				DETAIL: 'route_name.compensations.corrections.in_progress.detail',
			},
			REQUESTED: {
				INDEX: 'route_name.compensations.corrections.requested.index',
				DETAIL: 'route_name.compensations.corrections.requested.detail',
			},
			APPROVED: {
				INDEX: 'route_name.compensations.corrections.approved.index',
				DETAIL: 'route_name.compensations.corrections.approved.detail',
			},
			EXPORTED: {
				INDEX: 'route_name.compensations.corrections.exported.index',
				DETAIL: 'route_name.compensations.corrections.exported.detail',
			},
			REJECTED: {
				INDEX: 'route_name.compensations.corrections.rejected.index',
				DETAIL: 'route_name.compensations.corrections.rejected.detail',
			},
		},
	},
	CUSTOMERS: {
		CREATE: 'route_name.customers.create',
		DETAIL: {
			ABSENCES: {
				INDEX: 'route_name.customers.detail.absence.index',
			},
			CONTRACT: {
				INDEX: 'route_name.customers.detail.contract.index',
				SUBSCRIPTION: 'route_name.customers.detail.contract.subscription',
				TERMS_OF_SERVICE: 'route_name.customers.detail.contract.terms_of_service',
			},
			DOCUMENTS: {
				INDEX: 'route_name.customers.detail.documents.index',
			},
			INDEX: 'route_name.customers.detail.index',
			INFO: {
				ACTIVATION: 'route_name.customers.detail.info.activation',
				FINANCIAL: 'route_name.customers.detail.info.financial',
				INDEX: 'route_name.customers.detail.info.index',
				LEAD: 'route_name.customers.detail.info.lead',
				PERSONAL: 'route_name.customers.detail.info.personal',
				REFERRAL: 'route_name.customers.detail.info.referral',
				CANCELLATION: 'route_name.customers.detail.info.cancellation',
				REJECTION: 'route_name.customers.detail.info.rejection',
				WORK_STATION: 'route_name.customers.detail.info.work_station',
			},
			NOTIFICATIONS: {
				DAMAGE_CLAIMS: 'route_name.customers.detail.notification.damage_claims',
				INDEX: 'route_name.customers.detail.notification.index',
				WORK_ACCIDENTS: 'route_name.customers.detail.notification.work_accidents',
			},
			OVERVIEW: {
				DASHBOARD: 'route_name.customers.detail.overview.dashboard',
				INDEX: 'route_name.customers.detail.overview.index',
				LOGBOOKS: 'route_name.customers.detail.overview.logbook',
				TODOS: 'route_name.customers.detail.overview.todo',
			},
			PLANNING: {
				AVAILABILITIES: 'route_name.customers.detail.planning.availabilities',
				EMPLOYEES: 'route_name.customers.detail.planning.employees',
				INDEX: 'route_name.customers.detail.planning.index',
				WEEK_PLANNING: 'route_name.customers.detail.planning.week_planning',
			},
			SERVICE_CHECKS: {
				CHECKS: 'route_name.customers.detail.service_checks.checks',
				INDEX: 'route_name.customers.detail.service_checks.index',
				SHORTAGES: 'route_name.customers.detail.service_checks.shortages',
			},
			TO_DOS: {
				INDEX: 'route_name.customers.detail.to_dos.index',
				OPEN: 'route_name.customers.detail.to_dos.open',
				COMPLETED: 'route_name.customers.detail.to_dos.completed',
			},
		},
		INDEX: 'route_name.customers.index',
		PROSPECT: {
			INDEX: 'route_name.customers.prospect',
		},
	},
	DASHBOARD: 'route_name.dashboard',
	GLOBAL_SEARCH: 'route_name.global_search',
	ANOMALIES: {
		INDEX: 'route_name.anomalies.index',
		EXCESS: {
			INDEX: 'route_name.anomalies.excess.index',
		},
		SHORTAGES: {
			INDEX: 'route_name.anomalies.shortages.index',
		},
		TO_VALIDATE: {
			INDEX: 'route_name.anomalies.to_validate.index',
		},
		DETAIL: {
			INDEX: 'route_name.anomalies.detail.index',
		},
	},
	DEBTORS: {
		CUSTOMERS: {
			CASE_DETAIL: 'route_name.debtors.customers.case_detail',
			CLOSED: 'route_name.debtors.customers.closed',
			CUSTOMER_DETAIL: 'route_name.debtors.customers.customer_detail',
			INDEX: 'route_name.debtors.customers.index',
			NEW: 'route_name.debtors.customers.new',
			OPEN: 'route_name.debtors.customers.open',
		},
		EMPLOYEES: {
			DETAIL: 'route_name.debtors.customers.detail',
			INDEX: 'route_name.debtors.employees.index',
		},
		INDEX: 'route_name.debtors.index',
	},
	EMPLOYEES: {
		ACTIVE: 'route_name.employees.active',
		ALL: 'route_name.employees.all',
		APPLICATION: {
			INDEX: 'route_name.employees.application.index',
		},
		CANDIDATE: {
			INDEX: 'route_name.employees.candidate.index',
		},
		CANDIDATES: 'route_name.employees.candidates',
		CONCEPTS: 'route_name.employees.concepts',
		DETAIL: {
			ABSENCES: {
				ACCIDENTS: 'route_name.employees.detail.absences.accidents',
				ILLNESS: 'route_name.employees.detail.absences.illness',
				INDEX: 'route_name.employees.detail.absences.index',
				LEAVE: 'route_name.employees.detail.absences.leave',
				OTHER: 'route_name.employees.detail.absences.other',
				PART_TIME: 'route_name.employees.detail.absences.part_time',
				PART_TIME_UPDATE: 'route_name.employees.detail.absences.part_time_update',
				PREGNANCY: 'route_name.employees.detail.absences.pregnancy',
				COUNTERS: 'route_name.employees.detail.absences.counters',
			},
			COMPENSATION: {
				ADVANCE: 'route_name.employees.detail.compensation.advance-payment-rejection',
				REFERRAL_PREMIUMS: 'route_name.employees.detail.compensation.referral_premiums',
				ECONOMIC_UNEMPLOYMENT: 'route_name.employees.detail.compensation.economic_unemployment',
				INDEX: 'route_name.employees.detail.compensation.index',
				TAX_WITHHOLDING: 'route_name.employees.detail.compensation.tax_withholding',
				WAGE: 'route_name.employees.detail.compensation.wage',
				WAGE_BENEFITS: 'route_name.employees.detail.compensation.benefits',
			},
			CONTRACTS: {
				CONCEPT: 'route_name.employees.detail.contracts.concept',
				DETAIL: 'route_name.employees.detail.contracts.detail',
				INDEX: 'route_name.employees.detail.contracts.index',
				OVERVIEW: 'route_name.employees.detail.contracts.overview',
				TIMETABLE_CONCEPT: 'route_name.employees.detail.contracts.timetable_concept',
				TIMETABLE_CORRECTION_CONCEPT: 'route_name.employees.detail.contracts.timetable_correction_concept',
				DIMONA_DECLARATIONS_INDEX: 'route_name.employees.detail.contracts.dimona_declarations_index',
			},
			DOCUMENTS: {
				INDEX: 'route_name.employees.detail.documents.index',
			},
			INDEX: 'route_name.employees.detail.index',

			INFO: {
				APPLICATION: 'route_name.employees.detail.info.application',
				FISCAL: 'route_name.employees.detail.info.fiscal',
				INDEX: 'route_name.employees.detail.info.index',
				LEAD: 'route_name.employees.detail.info.lead',
				PERSONAL: 'route_name.employees.detail.info.personal',
				PROFESSIONAL: 'route_name.employees.detail.info.professional',
				SUBSIDIES: 'route_name.employees.detail.info.subsidies',
				PLUXEE_LOGIN: 'route_name.employees.detail.info.pluxee_login',
				CANCELLATION: 'route_name.employees.detail.info.cancellation',
				REJECTION: 'route_name.employees.detail.info.rejection',
			},
			NOTIFICATIONS: {
				DAMAGE_CLAIMS: 'route_name.employees.detail.notification.damage_claims',
				INDEX: 'route_name.employees.detail.notification.index',
				WORK_ACCIDENTS: 'route_name.employees.detail.notification.work_accidents',
			},
			OVERVIEW: {
				DASHBOARD: 'route_name.employees.detail.overview.dashboard',
				INDEX: 'route_name.employees.detail.overview.index',
				LOGBOOKS: 'route_name.employees.detail.overview.logbooks',
				TODOS: 'route_name.employees.detail.overview.todos',
			},
			PLANNING: {
				AVAILABILITIES: 'route_name.employees.detail.planning.availabilities',
				CUSTOMERS: 'route_name.employees.detail.planning.customers',
				INDEX: 'route_name.employees.detail.planning.index',
				CALENDAR: 'route_name.employees.detail.planning.calendar',
			},
			SERVICE_CHECKS: {
				CHECKS: {
					INDEX: 'route_name.employees.detail.service_checks.checks.index',
				},
				INDEX: 'route_name.employees.detail.service_checks.index',
				SHORTAGES: {
					INDEX: 'route_name.employees.detail.service_checks.shortages.index',
				},
				TREATABLE: {
					INDEX: 'route_name.employees.detail.service_checks.treatable.index',
				},
			},
			TO_DOS: {
				INDEX: 'route_name.employees.detail.to_dos.index',
				OPEN: 'route_name.employees.detail.to_dos.open',
				COMPLETED: 'route_name.employees.detail.to_dos.completed',
			},
		},
		DETAIL_OVERVIEW: 'route_name.employees.detail.overview',
		ENDING: 'route_name.employees.ending',
		INACTIVE: 'route_name.employees.inactive',
		INDEX: 'route_name.employees.index',
		NOT_RETAINED: 'route_name.employees.not.retained',
		QUARANTINE: 'route_name.employees.quarantine',
	},
	ERROR: 'route_name.error',
	GENERAL: 'route_name.general',
	LOGBOOKS: {
		INDEX: 'route_name.logbook.index',
	},
	MATCHING_CENTER: {
		INDEX: 'route_name.matching_center.index',
		CUSTOMERS: 'route_name.matching_center.customers',
		EMPLOYEES: 'route_name.matching_center.employees',
		RESERVATIONS: 'route_name.matching_center.reservations',
	},
	NEWS: {
		CONCEPTS: 'route_name.news.concepts',
		CREATE: 'route_name.news.create',
		DETAIL: 'route_name.news.detail',
		EDIT: 'route_name.news.edit',
		INDEX: 'route_name.news.index',
		PLANNED: 'route_name.news.planned',
	},
	PUBLIC: 'route_name.public',
	PUBLIC_CUSTOMER_CHECKLIST: {
		INDEX: 'route_name.public.customer_checklist.index',
		DOWNLOAD: 'route_name.public.customer_checklist.download',
	},
	PUBLIC_TERM_OF_SERVICE: {
		INDEX: 'route_name.public_term_of_service.index',
	},
	RAPPORTS: {
		ACCIDENTS: 'route_name.rapports.accidents',
		ADVANCES: 'route_name.rapports.advances',
		APPLICATION_PREMIUM: 'route_name.rapports.application_premium',
		DAMAGE: 'route_name.rapports.damage',
		DEBITOR: 'route_name.rapports.debitor',
		FLEET_MANAGER: 'route_name.rapports.fleet_manager',
		PAYCHECKS: 'route_name.rapports.paychecks',
	},
	REMITTANCE: {
		DETAIL: 'route_name.remittance.detail',
		INDEX: 'route_name.remittance.index',
	},
	SETTINGS: {
		APPLICATION: {
			ABSENCE_CODES: 'route_name.settings.application.absence_codes',
			FAQ: 'route_name.settings.application.faq',
			INDEX: 'route_name.settings.application.index',
			INFO_BUBBLES: 'route_name.settings.application.info_bubbles',
			PLANNING_REMARKS: 'route_name.settings.application.planning_remarks',
			TERMS_OF_SERVICE: 'route_name.settings.application.terms_of_service',
			TRANSLATIONS: 'route_name.settings.application.translations',
		},
		BUSINESSES: {
			DEPARTMENTS: 'route_name.settings.businesses.departments',
			DEPARTMENT_DETAIL: 'route_name.settings.businesses.department.detail',
			DISTRICTS: 'route_name.settings.businesses.districts',
			DISTRICT_DETAIL: 'route_name.settings.businesses.districts.detail',
			DISTRICT_DETAIL_NO_DM: 'route_name.settings.businesses.districts.detail.no_dm',
			INDEX: 'route_name.settings.businesses.index',
			OFFICES: 'route_name.settings.businesses.office',
			OFFICE_DETAIL: 'route_name.settings.businesses.office.detail',
			ORIGIN_COMPANIES: 'route_name.settings.businesses.origin_companies',
			ORIGIN_COMPANY_DETAIL: 'route_name.settings.businesses.origin_company.detail',
			PAYROLLS: 'route_name.settings.businesses.payroll_overview',
			PAYROLL_MERGES: 'route_name.settings.businesses.payroll_merges',
			DEPARTMENT_MERGES: 'route_name.settings.businesses.department_merges',
			PAYROLL_MERGE_DETAIL: 'route_name.settings.businesses.payroll_merge.detail',
			DEPARTMENT_MERGE_DETAIL: 'route_name.settings.businesses.department_merge.detail',
			PAYROLL_DETAIL: {
				EMPLOYEES: 'route_name.settings.businesses.payroll.detail.employees',
				INDEX: 'route_name.settings.businesses.payroll.detail.index',
				OFFICES: 'route_name.settings.businesses.payroll.detail.offices',
			},
		},
		COMPENSATION: {
			ABSENCE_CODES: 'route_name.settings.compensation.absence_codes',
			ADVANCE_AMOUNT: 'route_name.settings.compensation.advance_amount',
			BAREMA: 'route_name.settings.compensation.barema',
			BENEFIT_CODES: 'route_name.settings.compensation.benefit_codes',
			HOLIDAYS: 'route_name.settings.compensation.holidays',
			INDEX: 'route_name.settings.compensation.index',
			PERIODS_ECONOMIC_UNEMPLOYMENT: 'route_name.settings.compensation.periods_economic_unemployment',
		},
		INDEX: 'route_name.settings.index',
		SERVICE_CHECKS: {
			INDEX: 'route_name.settings.service_checks.index',
			REIMBURSEMENT_VALUE: 'route_name.settings.service_checks.reimbursement',
		},
		SUBSCRIPTIONS: {
			CONFIGURATION: 'route_name.settings.subscriptions.configuration',
			CONFIGURATION_DETAIL: 'route_name.settings.subscriptions.configuration_detail',
			HOME_MAID: 'route_name.settings.subscriptions.home_main',
			INDEX: 'route_name.settings.subscriptions.index',
			TRIXXA: 'route_name.settings.subscriptions.trixxa',
		},
		SUBSIDY: {
			ALL: 'route_name.settings.subsidy.all',
			CREATE: 'route_name.settings.subsidy.create',
			DETAIL: 'route_name.settings.subsidy.detail',
			EDIT: 'route_name.settings.subsidy.edit',
			INDEX: 'route_name.settings.subsidy.index',
			SUBSIDIES: 'route_name.settings.subsidies',
		},
		SYNCHRONISATIONS: {
			DIMONA: 'route_name.settings.synchronisations.dimona-declaration',
			EMMA: 'route_name.settings.synchronisations.emma',
			INDEX: 'route_name.settings.synchronisations.index',
			PARTENA: {
				INDEX: 'route_name.settings.synchronisations.partena.index',
				TIME_FILES: 'route_name.settings.synchronisations.partena.time_files',
				TIME_FILES_DETAIL: 'route_name.settings.synchronisations.partena.time_files_detail',
				ERP_FILES: 'route_name.settings.synchronisations.partena.erp_files',
				ERP_FILES_DETAIL: 'route_name.settings.synchronisations.partena.erp_files_detail',
				RECORDS: 'route_name.settings.synchronisations.partena.records',
				WARNINGS: 'route_name.settings.synchronisations.partena.warnings',
				VARIABLE_SCHEDULES: 'route_name.settings.synchronisations.partena.variable_schedules',
				WAGE_FILES: 'route_name.settings.synchronisations.partena.wage_files',
				WAGE_FILES_DETAIL: 'route_name.settings.synchronisations.partena.wage_files_detail',
			},
			PARTENA_EXPORT_FILES: 'route_name.settings.synchronisations.export_files',
			RSZ: {
				INDEX: 'route_name.settings.synchronisations.rsz.index',
				NOTIFICATIONS: {
					INDEX: 'route_name.settings.synchronisations.rsz.notification.index',
					DETAIL: 'route_name.settings.synchronisations.rsz.notification.detail',
				},
				DECLARATIONS: {
					INDEX: 'route_name.settings.synchronisations.rsz.declarations.index',
					DETAIL: 'route_name.settings.synchronisations.rsz.declarations.detail',
				},
				VALIDATION_BOOK: {
					INDEX: 'route_name.settings.synchronisations.rsz.validation_book.index',
				},
			},
			PLUXEE: {
				INDEX: 'route_name.settings.synchronisations.pluxee-login-login.index',
				LOGINS: 'route_name.settings.synchronisations.pluxee-login-login.logins',
			},
			JOB: {
				INDEX: 'route_name.settings.synchronisations.job.index',
				CURRENT: 'route_name.settings.synchronisations.cron_job.current',
				ARCHIVED: 'route_name.settings.synchronisations.cron_job.archived',
			},
			CRON_JOB: {
				INDEX: 'route_name.settings.synchronisations.cron_job.index',
			},
		},
		USERS: {
			INDEX: 'route_name.settings.users.index',
			ROLES_AND_PERMISSIONS: 'route_name.settings.users.roles_and_permissions',
			STAFF: 'route_name.settings.users.users',
			STAFF_DETAIL: 'route_name.settings.users.users.detail',
		},
	},
	SUBSIDIES: {
		DETAIL: 'route_name.subsidies.detail',
		INDEX: 'route_name.subsidies.index',
	},
	TODO: {
		FOLLOW_UP: 'route_name.todo.follow_up',
		OPEN: 'route_name.todo.open',
		CLOSED: 'route_name.todo.closed',
		INDEX: 'route_name.todo.index',
	},
}

export default ROUTE_NAME
